import { render, staticRenderFns } from "./BankTransferList.vue?vue&type=template&id=2371768d"
import script from "./BankTransferList.vue?vue&type=script&lang=js"
export * from "./BankTransferList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports