<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page" :pagination="table.pagination"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import {BankAccountService as Service, BankListService} from '@/services'

export default {
  name: 'Tables',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data() {
    return {
      table: {
        page: {
          title: 'Banka Hesap Listesi',
          icon: 'bank',
          actions: [
            {
              title: 'Banka Hesabı Oluştur',
              type: 'success',
              icon: 'plus',
              action: () => {
                this.$router.push({name: 'bankTransferAccountCreate'})
              },
              perm: 'BankTransferAccount.Create'
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => {
                this.$refs.table.loadAsyncData()
              }
            }
          ]
        },
        filter: [
          {
            type: 'input',
            value: null,
            field: 'name',
            name: 'Hesap Adı'
          },
          {
            type: 'input',
            value: null,
            field: 'iban',
            name: 'IBAN'
          },
          {
            type: 'input',
            value: null,
            field: 'bankName',
            name: 'Banka'
          },
          {
            type: 'input',
            value: 'BANK_TRANSFER',
            field: 'bankType',
            name: 'Banka Türleri',
            hidden: true
          },
          {
            type: 'enum',
            value: 'ENABLED',
            field: 'status',
            name: 'Durum',
            enumType: 'bankAccountStatuses'
          }, {
            type: 'select',
            value: null,
            data: [{uuid: 1, name: 'Auto'}, {uuid: 0, name: 'Manual'}],
            field: 'isAuto',
            name: 'Auto Bank'
          }
        ],
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'name',
              label: 'Banka Hesap Adı',
              sortable: true,
            },
            {
              field: 'iban',
              label: 'IBAN',
              sortable: true
            },
            {
              field: 'bankList.name',
              label: 'Banka',
              sortable: true,
              column: 'row',
              renderHtml: true,
              viewMethod: val => {
                let logo = process.env.VUE_APP_SITE_URL + "logos/banks/papara.png";
                if (val.bank.type === "PAYFIX")
                  logo = process.env.VUE_APP_SITE_URL + "logos/banks/payfix.png";
                if (val.bank.type === "TETHER")
                  logo = process.env.VUE_APP_SITE_URL + "logos/banks/tether.svg";
                if (val.bank.type === "BANK_TRANSFER" || val.bank.type === 'FOREX') {
                  logo = val.bankList?.logo;
                }
                return `<img width="70" style="max-width:70px" src="${logo}" />`;
              }
            },
            {
              field: 'dailyLimit',
              label: 'Min',
              column: 'row',
              renderHtml: true,
              viewMethod: data => {
                return `₺${this.numberFormat(data.minimumLimit)}`
              }
            },
            {
              field: 'dailyLimit',
              label: 'Max',
              column: 'row',
              renderHtml: true,
              viewMethod: data => {
                return `₺${this.numberFormat(data.maximumLimit)}`
              }
            },
            {
              field: 'isAuto',
              label: 'Oto',
              sortable: true,
              renderHtml: true,
              column: 'row',
              viewMethod: val => {
                return val.method === 'BANK_TRANSFER' ? (`<span class="tag ${val.isAuto ? 'is-success' : 'is-info'}">${val.isAuto ? 'OTO' : 'MANUAL'}</span>`) : '-'
              }
            },
            {
              field: "fast",
              label: "FAST",
              sortable: true,
              renderHtml: true,
              column: "row",
              viewMethod: (val) => {
                return val.method === "BANK_TRANSFER"
                    ? `<span class="tag ${
                        val.fast
                            ? "is-success"
                            : "is-warning"
                    }">${
                        val.fast ? "FAST" : "NOT FAST"
                    }</span>`
                    : "-";
              },
            },
            {
              field: 'status',
              label: 'Durum',
              sortable: true,
              renderHtml: true,
              viewMethod: val => `<span class="tag ${val === 'ENABLED' ? 'is-success' : (val === 'RESERVED' ? 'is-warning' : 'is-danger')}">${val === 'ENABLED' ? 'Aktif' : (val === 'RESERVED' ? 'Yedek' : 'Pasif')}</span>`
            },
            {
              field: 'sequence',
              label: 'Sıra',
              sortable: true
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDate)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Düzenle',
                  type: 'success',
                  column: 'row',
                  action: data => this.$router.push({name: 'bankTransferAccountCreate', params: {id: data.uuid}}),
                  perm: 'BankTransferAccount.Create'
                }
              ]
            }
          ]
        },
        pagination: {
          paginate: 500,
          page: 1,
          sortField: 'name',
          sortOrder: 'asc',
          defaultSortOrder: 'asc'
        }
      }
    }
  },
  mounted() {
    this.getBanks()
  },
  methods: {
    getBanks() {
      BankListService.all().then(({data}) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'bankListId',
          name: 'Banka'
        })
      })
    },
  }
}
</script>
